import React from 'react'
import { animated } from 'react-spring'

import Portfolio01Src from '../images/Portfolio01.jpg'
import Portfolio02Src from '../images/Portfolio02.jpg'
import Portfolio03Src from '../images/Portfolio03.jpg'
import Portfolio04Src from '../images/Portfolio04.jpg'
import Portfolio05Src from '../images/Portfolio05.jpg'
import Portfolio06Src from '../images/Portfolio06.jpg'
import Portfolio07Src from '../images/Portfolio07.jpg'
import Portfolio08Src from '../images/Portfolio08.jpg'
import Portfolio09Src from '../images/Portfolio09.jpg'
import Portfolio10Src from '../images/Portfolio10.jpg'
import Portfolio11Src from '../images/Portfolio11.jpg'
import Portfolio12Src from '../images/Portfolio12.jpg'
import Portfolio13Src from '../images/Portfolio13.jpg'
import Portfolio14Src from '../images/Portfolio14.jpg'
import Portfolio15Src from '../images/Portfolio15.jpg'
import Portfolio16Src from '../images/Portfolio16.jpg'
import Portfolio17Src from '../images/Portfolio17.jpg'
import Portfolio18Src from '../images/Portfolio18.jpg'
import styles from './Card07Portfolio.module.css'

const images = [
  Portfolio01Src,
  Portfolio02Src,
  Portfolio03Src,
  Portfolio04Src,
  Portfolio05Src,
  Portfolio06Src,
  Portfolio07Src,
  Portfolio08Src,
  Portfolio09Src,
  Portfolio10Src,
  Portfolio11Src,
  Portfolio12Src,
  Portfolio13Src,
  Portfolio14Src,
  Portfolio15Src,
  Portfolio16Src,
  Portfolio17Src,
  Portfolio18Src
]

const Card07Portfolio = props => (
  <animated.div
    {...props}
    className={`${props.className || ''} ${styles.Card07Portfolio}`}
  >
    <ul>
      {images.map((image, i) => (
        <li key={i}>
          <img src={image} alt="" draggable="false" />
        </li>
      ))}
    </ul>
    <a
      className={styles.Card07Portfolio_Button}
      href="https://www.dropbox.com/s/usjdiocp2tmocha/RABBIT_220701.pdf?dl=0"
      draggable="false"
    >
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.0156 13H14.0156V9.01562H9.98438V13H6.98438L12 18.0156L17.0156 13ZM19.3594 10.0469C20.6406 10.1406 21.7344 10.6641 22.6406 11.6172C23.5469 12.5703 24 13.7031 24 15.0156C24 16.3906 23.5078 17.5703 22.5234 18.5547C21.5391 19.5391 20.3594 20.0312 18.9844 20.0312H6C4.34374 20.0312 2.92969 19.4453 1.75781 18.2734C0.585932 17.1016 0 15.6875 0 14.0312C0 12.5 0.51562 11.1641 1.54688 10.0234C2.57813 8.88281 3.84374 8.23438 5.34375 8.07812C6 6.85937 6.92187 5.875 8.10938 5.125C9.29688 4.375 10.5937 4 12 4C13.8125 4 15.4062 4.57031 16.7812 5.71094C18.1563 6.85157 19.0156 8.29687 19.3594 10.0469Z" />
      </svg>
      <div>
        Download
        <br />
        Portfolio
      </div>
    </a>
  </animated.div>
)

export default Card07Portfolio

const rotate = (Math.random() - 0.5) * 5

export const to = () => ({
  x: (typeof window !== 'undefined' ? window.innerHeight : 0) * 0,
  y: (typeof window !== 'undefined' ? window.innerHeight : 0) * -0.05,
  rotate
})
