import React from 'react'
import { animated } from 'react-spring'

import styles from './Card05Vision.module.css'

const Card05Vision = props => (
  <animated.div
    {...props}
    className={`${props.className || ''} ${styles.Card05Vision}`}
  >
    <section>
      <h1>Vision</h1>
      <h2>
        いいもので
        <br />
        いいこと。
      </h2>
      <p>
        「いいものを創ること」を
        <br />
        シンプルに。一生懸命に。
      </p>
      <p>
        デザイン×広告で。
        <br />
        デザイン×PRで。
        <br />
        ビジネス事業全体の仕組みづくりで。
      </p>
      <p>
        流行り廃りではない、
        <br />
        本質的なブランディングと
        <br />
        コミュニケーションデザインを
        <br />
        目指します。
      </p>
    </section>
  </animated.div>
)

export default Card05Vision

const rotate = (Math.random() - 0.5) * 5

export const to = () => ({
  x: (typeof window !== 'undefined' ? window.innerHeight : 0) * -0.025,
  y: (typeof window !== 'undefined' ? window.innerHeight : 0) * 0.05,
  rotate
})
