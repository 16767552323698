import { useState, useEffect } from 'react'

const useWindowHeight = () => {
  const [height, setHeight] = useState(0)

  useEffect(() => {
    const update = () => setHeight(window.innerHeight)
    window.addEventListener('resize', update)
    update()
    return () => window.removeEventListener('resize', update)
  })

  return height
}

export default useWindowHeight
