import React from 'react'
import { animated } from 'react-spring'

import { SerifLeft, SerifRight } from './Serif'
import PortraitSrc from '../images/Portrait.jpg'
import styles from './Card02Portrait.module.css'

const Card02Portrait = props => (
  <animated.div
    {...props}
    className={`${props.className || ''} ${styles.Card02Portrait}`}
  >
    <img src={PortraitSrc} alt="Portrait" draggable="false" />
    <SerifLeft className={styles.isLeft} />
    <SerifRight className={styles.isRight} />
  </animated.div>
)

export default Card02Portrait

const rotate = (Math.random() - 0.5) * 5

export const to = () => ({
  x: (typeof window !== 'undefined' ? window.innerHeight : 0) * -0.1,
  y: (typeof window !== 'undefined' ? window.innerHeight : 0) * -0.12,
  rotate
})
