import React, { useState, useCallback } from 'react'
import { useSprings, animated, interpolate } from 'react-spring'
import { useDrag } from 'react-use-gesture'

import Card01Logo, { to as to01 } from './Card01Logo'
import Card02Portrait, { to as to02 } from './Card02Portrait'
import Card03Name, { to as to03 } from './Card03Name'
import Card04Career, { to as to04 } from './Card04Career'
import Card05Vision, { to as to05 } from './Card05Vision'
import Card06Business, { to as to06 } from './Card06Business'
import Card07Portfolio, { to as to07 } from './Card07Portfolio'
import styles from './Deck.module.css'
import useWindowHeight from '../hooks/useWindowHeight'

const cards: [React.FC, () => { x: number; y: number; rotate: number }][] = [
  [Card07Portfolio, to07],
  [Card06Business, to06],
  [Card05Vision, to05],
  [Card04Career, to04],
  [Card03Name, to03],
  [Card02Portrait, to02],
  [Card01Logo, to01]
]

const dummyWindow = { innerWidth: 3000, innerHeight: 3000 }

const isAnchor = (el: HTMLElement): Boolean => {
  if (!el) return false
  if (el.tagName === 'A') return true
  return isAnchor(el.parentNode as HTMLElement)
}

const Deck: React.FC = () => {
  const [emojiVisible, setEmojiVisible] = useState(false)
  const [clickAvailable, setClickAvailable] = useState(true)
  const [gone] = useState(() => new Set())
  const windowHeight = useWindowHeight()

  const [props, set] = useSprings(cards.length, i => {
    const [, to] = cards[i]
    const props = to()

    const { innerWidth, innerHeight } =
      typeof window !== 'undefined' ? window : dummyWindow
    const diagonal = Math.sqrt(innerWidth ** 2 + innerHeight ** 2)
    const radius = Math.random() * Math.PI * 2
    const from = {
      x: Math.cos(radius) * diagonal * 1.5,
      y: Math.sin(radius) * diagonal * 1.5,
      rotate: props.rotate + (Math.random() * 180 - 90),
      opacity: 1,
      scale: 1
    }

    return {
      opacity: 1,
      scale: 1,
      delay: i * 200,
      pointerEvents: 'auto',
      ...props,
      from
    }
  })

  const onClick = useCallback(
    e => {
      if (!clickAvailable) e.preventDefault()
    },
    [clickAvailable]
  )

  const bind = useDrag(
    ({
      args: [index],
      movement: [mx, my],
      direction: [dx, dy],
      down,
      velocity,
      first,
      last,
      event
    }) => {
      const insideAnchor = isAnchor(event.target as HTMLElement)
      const pointerClick =
        event.type === 'mouseup' &&
        !last &&
        typeof window !== 'undefined' &&
        window.matchMedia('(hover: hover)').matches &&
        !insideAnchor
      const touchClick = event.type === 'touchend' && !last && !insideAnchor

      let click = false
      if (pointerClick || touchClick) {
        gone.add(index)
        click = true
      } else if (!down && velocity > 0.2) {
        gone.add(index)
      }

      if (first) setClickAvailable(false)
      if (last) setTimeout(() => setClickAvailable(true), 0)

      setEmojiVisible(true)

      set(i => {
        if (i !== index) return

        const initialProps = cards[i][1]()
        const { innerWidth, innerHeight } =
          typeof window !== 'undefined' ? window : dummyWindow
        const diagonal = Math.sqrt(innerWidth ** 2 + innerHeight ** 2)

        if (click) {
          const radius = Math.random() * (Math.PI * 2)

          setTimeout(
            () => set(i => (i === index ? { opacity: 0 } : undefined)),
            600
          )
          return {
            x: initialProps.x + Math.cos(radius) * diagonal * 0.4,
            y: initialProps.y + Math.sin(radius) * diagonal * 0.4,
            rotate: initialProps.rotate + Math.random() * 10,
            opacity: 0,
            scale: 1,
            delay: undefined,
            pointerEvents: 'none',
            config: { friction: 50, tension: 200 }
          }
        } else if (gone.has(index)) {
          const radius = Math.atan2(dy, dx)
          const dirX = dx < 0 ? -1 : 1

          setTimeout(
            () => set(i => (i === index ? { opacity: 0 } : undefined)),
            600
          )
          return {
            x: initialProps.x + Math.cos(radius) * diagonal * velocity * 0.4,
            y: initialProps.y + Math.sin(radius) * diagonal * velocity * 0.4,
            rotate: initialProps.rotate + mx / 100 + dirX * 10 * velocity,
            opacity: 0,
            scale: 1,
            delay: undefined,
            pointerEvents: 'none',
            config: { friction: 50, tension: 200 }
          }
        } else if (down) {
          return {
            x: initialProps.x + mx,
            y: initialProps.y + my,
            rotate: initialProps.rotate + mx / 100,
            opacity: 1,
            scale: 1.1,
            delay: undefined,
            pointerEvents: 'auto',
            config: { friction: 50, tension: 800 }
          }
        } else {
          return {
            x: initialProps.x + 0,
            y: initialProps.y + 0,
            rotate: initialProps.rotate + mx / 100,
            opacity: 1,
            scale: 1,
            delay: undefined,
            pointerEvents: 'auto',
            config: { friction: 50, tension: 500 }
          }
        }
      })

      if (!down && gone.size === cards.length) {
        setTimeout(() => {
          gone.clear()
          set(i => {
            const initialProps = cards[i][1]()
            return {
              opacity: 1,
              scale: 1,
              delay: i * 200,
              pointerEvents: 'auto',
              ...initialProps
            }
          })
        }, 800)
      }
    },
    {
      threshold: 10
    }
  )

  return (
    <div className={styles.Deck} onClickCapture={onClick}>
      <div
        className={styles.Deck_Emoji}
        style={{ opacity: emojiVisible ? 1 : 0, height: windowHeight }}
      >
        🐰
      </div>
      {props.map(({ x, y, rotate, opacity, scale, pointerEvents }, i) => {
        const [Card] = cards[i]

        return (
          <animated.div
            key={i}
            className={styles.Deck_CardBase}
            style={{
              opacity,
              pointerEvents: interpolate([pointerEvents], pointerEvents =>
                pointerEvents === 'auto'
                  ? 'auto'
                  : pointerEvents === 'none'
                  ? 'none'
                  : null
              ),
              transform: interpolate(
                [x, y],
                (x, y) => `translate3d(${x}px, ${y}px, 0)`
              )
            }}
          >
            <Card
              {...bind(i)}
              style={{
                boxShadow: interpolate([scale], scale => {
                  const ratio = (scale - 1) * 10
                  return `
                    0 ${ratio * 0.5}vh ${ratio * 1}vh rgba(0, 0, 0, 0.1),
                    0 ${ratio * 4}vh ${ratio * 8}vh rgba(0, 0, 0, 0.2)
                  `
                }),
                transform: interpolate(
                  [rotate, scale],
                  (rotate, scale) =>
                    `rotateY(${rotate /
                      10}deg) rotateZ(${rotate}deg) scale(${scale})`
                )
              }}
            />
          </animated.div>
        )
      })}
    </div>
  )
}

export default Deck
