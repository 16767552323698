import React from 'react'

import Deck from '../components/Deck'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

const IndexPage = () => (
  <Layout>
    <Seo />
    <Deck />
  </Layout>
)

export default IndexPage
