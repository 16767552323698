import React from 'react'

export const SerifLeft = props => (
  <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 100L100 100L100 0C100 55.2285 55.2285 100 0 100Z" />
  </svg>
)

export const SerifRight = props => (
  <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <path d="M100 100L7.62939e-06 100L0 0C4.82822e-06 55.2285 44.7716 100 100 100Z" />
  </svg>
)
