import React from 'react'
import { animated } from 'react-spring'

import { SerifLeft, SerifRight } from './Serif'
import styles from './Card06Business.module.css'

const Card06Business = props => (
  <animated.div
    {...props}
    className={`${props.className || ''} ${styles.Card06Business}`}
  >
    <div className={styles.Card06Business_End} />
    <section>
      <h1>Business Areas</h1>
      <ul>
        <li>Branding</li>
        <li>Creative Direction</li>
        <li>Art Direction</li>
        <li>Logo</li>
        <li>Web Design</li>
        <li>Animation</li>
        <li>Character</li>
        <li>Product</li>
        <li>Communication Design</li>
      </ul>
    </section>
    <SerifLeft className={styles.isLeft} />
    <SerifRight className={styles.isRight} />
  </animated.div>
)

export default Card06Business

const rotate = (Math.random() - 0.5) * 5

export const to = () => ({
  x: 0,
  y: 0,
  rotate
})
