import React from 'react'
import { animated } from 'react-spring'

import { SerifLeft, SerifRight } from './Serif'
import styles from './Card04Career.module.css'

const Card04Career = props => (
  <animated.div
    {...props}
    className={`${props.className || ''} ${styles.Card04Career}`}
  >
    <div className={styles.Card04Career_Group}>
      <section className={styles.isCareer}>
        <h1>Career</h1>
        <ul>
          <li>静岡県浜松市出身</li>
          <li>多摩美術大学グラフィックデザイン学科卒業後</li>
          <li>電通テック入社</li>
          <li>ADKを経て</li>
          <li>CHERRY INC.設立に参画</li>
          <li>RABBIT INC.設立</li>
        </ul>
      </section>
      <section className={styles.isAward}>
        <h1>Award</h1>
        <ul>
          <li>2023 Spikes Asia GOLD</li>
          <li>2023 Adfest SILVER</li>
          <li>2022 Adfest BRONZE</li>
          <li>2019 ACC GOLD</li>
          <li>2019 Cannes Lions BRONZE</li>
          <li>2019 D&AD Graphite Pencil</li>
          <li>2017 ACC GOLD</li>
          <li>2017 JAAA CREATOR OF THE YEAR メダリスト</li>
        </ul>
      </section>
    </div>
    <SerifLeft className={styles.isLeft} />
    <SerifRight className={styles.isRight} />
  </animated.div>
)

export default Card04Career

const rotate = (Math.random() - 0.5) * 5

export const to = () => ({
  x: (typeof window !== 'undefined' ? window.innerHeight : 0) * -0.25,
  y: (typeof window !== 'undefined' ? window.innerHeight : 0) * -0.05,
  rotate
})
