import React from 'react'
import { animated } from 'react-spring'

import { Instagram, Twitter, Facebook } from './Social'
import { SerifLeft, SerifRight } from './Serif'
import styles from './Card03Name.module.css'

const Card03Name = props => (
  <animated.div {...props} className={styles.Card03Name}>
    <ul className={styles.isTitle}>
      <li>CEO</li>
      <li>Creative Director</li>
      <li>Art Director</li>
    </ul>
    <ul className={styles.isName}>
      <li>増田総成</li>
      <li>Fusanari Masuda</li>
    </ul>
    <ul className={styles.isContact}>
      <li>
        <a href="tel:08020668087">080 2066 8087</a>
      </li>
      <li>
        <a href="mailto:masuda@rabbitinc.info">masuda@rabbitinc.info</a>
      </li>
    </ul>
    <ul className={styles.isSocial}>
      <li>
        <a
          href="https://www.instagram.com/fusanari.masuda"
          target="_blank"
          rel="noopener noreferrer"
          title="Instagram"
        >
          <Instagram />
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/rabbit__inc"
          target="_blank"
          rel="noopener noreferrer"
          title="Twitter"
        >
          <Twitter />
        </a>
      </li>
      <li>
        <a
          href="https://www.facebook.com/fusanari.masuda"
          target="_blank"
          rel="noopener noreferrer"
          title="Facebook"
        >
          <Facebook />
        </a>
      </li>
    </ul>
    <SerifLeft className={styles.isLeft} />
    <SerifRight className={styles.isRight} />
  </animated.div>
)

export default Card03Name

export const to = () => ({
  x: (typeof window !== 'undefined' ? window.innerHeight : 0) * 0.03,
  y: (typeof window !== 'undefined' ? window.innerHeight : 0) * 0.1,
  rotate: 0
})
