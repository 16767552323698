import React from 'react'
import { animated } from 'react-spring'

import { SerifLeft, SerifRight } from './Serif'
import LogoSrc from '../images/Logo.svg'
import styles from './Card01Logo.module.css'

const Card01Logo = props => (
  <animated.div
    {...props}
    className={`${props.className || ''} ${styles.Card01Logo}`}
  >
    <img src={LogoSrc} alt="Rabbit logo" draggable="false" />
    <SerifLeft className={styles.isLeft} />
    <SerifRight className={styles.isRight} />
  </animated.div>
)

export default Card01Logo

const rotate = -45

export const to = () => ({
  x: (typeof window !== 'undefined' ? window.innerHeight : 0) * 0.2,
  y: (typeof window !== 'undefined' ? window.innerHeight : 0) * 0.08,
  rotate
})
